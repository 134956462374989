window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('@fortawesome/fontawesome-pro/js/fontawesome');
require('./vendor/match-height');
require('./scripts/cookie');
require('./scripts/event');
require('./scripts/photoswipe');

import {Fef} from './classes/Fef';
import {FormElements} from './classes/FormElements';

(function ($) {
    /**
     * Initialise the Front-end Framework.
     *
     * @type {Fef}
     */

    let fef = new Fef({
        matchHeightSite: () => {
            //matchHeight
            $('.links .match').matchHeight();

            $('.section-bottom-image .match').matchHeight({
                ByRow: true
            });

            $('.boxes .boxes__item h2').matchHeight({
                ByRow: true
            });

            $('.match .item').matchHeight({
                ByRow: true
            });

            $('.interviews .match').matchHeight({
                ByRow: true
            });

            $('.vacancy__item .specs').matchHeight({
                ByRow: true
            });

            $('.vacancy__item h3').matchHeight({
                ByRow: true
            });

            $('.news .match .item').matchHeight({
                ByRow: true
            });

            $('.vestiging__col.match').matchHeight({
                ByRow: true
            });

            $('.contact__col.match').matchHeight({
                ByRow: true
            });

            $('.facts .item').matchHeight({
                ByRow: true
            });

            $('.procedure .procedure__item').matchHeight({
                ByRow: true
            });

            $('.faq .toggle-box .inner h2 span').matchHeight({
                ByRow: false
            });
        },
    });

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    $(".photoalbum .inner .inner").find("*").not("img").each(function () {
        $(this).replaceWith(this.innerHTML);
    });

    $(".photoalbum .inner .inner img").each(function (index, element) {
        $(element).wrap("<div class='item'></div>");
    });

    //Leftnav toggle
    $('.js-toggle-left-navigation').off('click').on('click', function () {
        var ToggleSubMenu = $('.left__col');
        var ToggleMenuButton = $('.js-toggle-left-navigation');

        if (ToggleSubMenu.hasClass('active')) {
            ToggleSubMenu.removeClass('active');
            ToggleMenuButton.removeClass('reverse active');
        } else {
            ToggleSubMenu.addClass('active');
            ToggleMenuButton.addClass('reverse active');
        }
        return false;
    });

    //Tabs FAQ
    $('ul.tabs-menu li').click(function () {
        var tab_id = $(this).attr('data-tab');

        if ($(window).outerWidth() <= fef.config.breakpoints.sm) {
            var divLoc = $('#idVal').offset();
            $('html, body').animate({scrollTop: divLoc.top}, "slow");
        }

        $('ul.tabs-menu li').removeClass('current');
        $('.tab-content').removeClass('current');
        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    });

    // scroll smooth
    $(function () {
        $('.content__col a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 150
                    }, 1000);
                    return false;
                }
            }
        });
    });

    //ReadSpeaker toggle
    var listenIsActive = false;

    $('.listen').click(function () {
        if (listenIsActive) {
            $('.readspeaker').removeClass('active');
            window.ReadSpeaker.ui.getActivePlayer().close();
            listenIsActive = false;
        } else {
            $('.readspeaker').addClass('active');
            $('.rsplay').click();
            listenIsActive = true;
        }
    });

    //HighContrast
    if (Cookies.get('BW') === 'true') {
        $('body').toggleClass('BW');
        $('.js-hero-slider').slick('slickPause');
    }

    $('.contrast').click(function () {
        $('body').toggleClass('BW');
        if ($('body').hasClass('BW')) {
            Cookies.set('BW', 'true');
            $('.js-hero-slider').slick('slickPause');
        } else {
            Cookies.set('BW', 'false');
            $('.js-hero-slider').slick('slickPlay');
        }
    });

    //Add spans HightContrast
    $('.hero__item h2').each(function () {
        let words = $(this).text().split(" ");
        let total = words.length;
        $(this).empty();
        for (let index = 0; index < total; index++) {
            $(this).append($("<span /> ").text(words[index]));
        }
    });

    /**
     * Fill form title based on event's title
     */
    $('.js-events-table tr').each(function (index, item) {
        $(this).addClass('js-event-row-' + index);
    });

    var labels = [];

    $('.input_38').each(function () {
        labels.push({
            radio: $(this).parents('label').find('input'),
            name: $.trim($(this).parents('label').text())
        });
    });

    $('.js-show-form').click(function () {
        var title = $.trim($(this).parents('tr').find('.js-event-title').html());
        var category = $.trim($(this).parents('tr').find('.js-event-category').html());
        var categoryWithoutLeadingDash = category.replace('- ', '');

        var result = $.grep(labels, function (e) {
            return e.name === categoryWithoutLeadingDash;
        });

        if ($(result).length === 0) {
            $('.input_38[value="30"]').prop('checked', true);
        } else {
            $(result[0].radio).prop('checked', true);
        }

        $('.input_37').val(title + ' ' + category);
    });

    // Addclass to image left__col
    $('.vestiging__col img').addClass('line');

    if ($('.melding').length > 0) {
        $('body').addClass('has-notification');
    } else {
        $('body').removeClass('has-notification');
    }

    // Images background instellen content
    $('.section-list-image .image__item').each(function () {
        var sectionImages = $(this);
        //set image Background
        if (sectionImages.find('img').length) {
            //hide original image
            sectionImages.find('img').hide();
            var imageURL = sectionImages.find('img').attr("src");
            // //set lightbox link
            // sectionImages.find('.image').attr('href', imageURL);
            //set css to each li
            sectionImages.find('.image').css({
                'background-image': 'url(\'' + imageURL + '\')',
                'background-position': 'center',
                'background-repeat': 'no-repeat',
                'overflow': 'hidden',
                'background-size': 'cover !important'
            });
        }
    });

    // Images background instellen content
    $('.section-list-image .image__item').each(function() {
        var sectionImages = $(this);
        //set image Background
        if(sectionImages.find('img').length){
            //hide original image
            sectionImages.find('img').hide();
            var imageURL = sectionImages.find('img').attr("src");
            //set lightbox link
            sectionImages.find('.image').attr('href', imageURL);
            //set css to each li
            sectionImages.find('.image').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'center',
                'background-repeat' : 'no-repeat',
                'overflow' : 'hidden',
                'background-size' : 'cover !important'
            });
        }
    });

    // Images background video home
    $('.section-list-image .video__item').each(function() {
        var sectionVideo = $(this);
        //set image Background
        if(sectionVideo.find('img').length){
            //hide original image
            sectionVideo.find('img').hide();
            var imageURL = sectionVideo.find('img').attr("src");
            //set css to each li
            sectionVideo.find('.video').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'center',
                'background-repeat' : 'no-repeat',
                'overflow' : 'hidden',
                'background-size' : 'cover !important'
            });
        }
    });


    // Images background video home
    $('.section-square-image .image__item').each(function() {
        var sectionSquare = $(this);
        //set image Background
        if(sectionSquare.find('img').length){
            //hide original image
            sectionSquare.find('img').hide();
            var imageURL = sectionSquare.find('img').attr("src");
            //set css to each li
            sectionSquare.find('.video').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'center',
                'background-repeat' : 'no-repeat',
                'overflow' : 'hidden',
                'background-size' : 'cover !important'
            });
        }
    });

    // Images background video home
    $('.section-square-image .image__item').each(function() {
        var sectionSquare = $(this);
        //set image Background
        if(sectionSquare.find('img').length){
            //hide original image
            sectionSquare.find('img').hide();
            var imageURL = sectionSquare.find('img').attr("src");
            //set css to each li
            sectionSquare.find('.image').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'center',
                'background-repeat' : 'no-repeat',
                'overflow' : 'hidden',
                'background-size' : 'cover !important'
            });
        }
    });


    // Images hero pages full with
    $('.hero__img').each(function() {
        var heroImage = $(this);
        //set image Background
        if(heroImage.find('img').length){
            //hide original image
            heroImage.find('img').hide();
            var imageURL = heroImage.find('img').attr("src");
            //set css to each li
            heroImage.find('.hero__item').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'center',
                'background-repeat' : 'no-repeat',
                'background-size' : 'cover !important'
            });
        }
    });


    // Images hero pages small
    $('.hero__img-small').each(function() {
        var heroSmall = $(this);
        //set image Background
        if(heroSmall.find('img').length){
            //hide original image
            heroSmall.find('img').hide();
            var imageURL = heroSmall.find('img').attr("src");
            //set css to each li
            heroSmall.find('.hero__item').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'center',
                'background-repeat' : 'no-repeat',
                'background-size' : 'cover !important'
            });
        }
    });

    // Images boxes
    $('.boxes .boxes__image').each(function() {
        var locationImage = $(this);
        //set image Background
        if(locationImage.find('img').length){
            //hide original image
            locationImage.find('img').hide();
            var imageURL = locationImage.find('img').attr("src");
            //set css to each li
            locationImage.find('.image').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'center',
                'background-repeat' : 'no-repeat',
                'background-size' : 'cover !important'
            });
        }
    });

    // Images
    $('.section-bottom-image .image__item').each(function() {
        var footerImage = $(this);
        //set image Background
        if(footerImage.find('img').length){
            //hide original image
            footerImage.find('img').hide();
            var imageURL = footerImage.find('img').attr("src");
            //set css to each li
            footerImage.find('.image').css({
                'background-image' : 'url(\''+imageURL+'\')',
                'background-position' : 'bottom',
                'background-repeat' : 'no-repeat',
                'background-size' : 'cover !important'
            });
        }
    });

    // Images Footer
    // $('.footer').each(function () {
    //     var footerImage = $(this);
    //     //set image Background
    //     if (footerImage.find('img').length) {
    //         //hide original image
    //         footerImage.find('img').hide();
    //         var imageURL = footerImage.find('img').attr("src");
    //         //set css to each li
    //         footerImage.find('.image__footer').css({
    //             'background-image': 'url(\'' + imageURL + '\')',
    //             'background-position': 'center',
    //             'background-repeat': 'no-repeat',
    //             'background-size': 'cover !important'
    //         });
    //     }
    // });

    // Images hero pages full with
    $('.hero__img').each(function () {
        var heroImage = $(this);
        //set image Background
        if (heroImage.find('img').length) {
            //hide original image
            heroImage.find('img').hide();
            var imageURL = heroImage.find('img').attr("src");
            //set css to each li
            heroImage.find('.hero__item').css({
                'background-image': 'url(\'' + imageURL + '\')',
                'background-position': 'center',
                'background-repeat': 'no-repeat',
                'background-size': 'cover !important'
            });
        }
    });

    //FAQ toggle ToggleBox
    $('.open-toggle').hide();
    $('.open').show();
    $('.closed').hide();
    $(".toggle").click(function () {
        $(this).closest('.toggle-box').find(".open-toggle").slideToggle(350, function () {
            if ($(this).closest('.toggle-box').hasClass('active')) {
                $(this).closest('.toggle-box').removeClass('active');
                $(this).closest('.toggle-box').find('.closed').hide();
                $(this).closest('.toggle-box').find('.open').show();
            } else {
                $(this).closest('.toggle-box').addClass('active');
                $(this).closest('.toggle-box').find('.closed').show();
                $(this).closest('.toggle-box').find('.open').hide();
            }
        });
    });

    if ($(document).hasClass('errorMsg')) {
        console.log('Error!');
        $('#inline').opener();
    }

    $(".tabs ul li").hover(function () {
        $(this).toggleClass("active");
    });

    $(".kaarstab ul li").hover(function () {
        $(this).toggleClass("active");
    });

})(jQuery);