class Sliders {
    constructor(config) {
        if (config.sliders.hero) {
            this.hero();
        }
    }

    hero() {
        $('.js-hero-slider').slick({
            infinite: true,
            speed: 1500,
            fade: true,
            rows: 0,
            cssEase: 'linear',
            arrows: false,
            autoplay: true,
            dots: true,
            autoplaySpeed: 4000
        });
    }
}

export {Sliders};