export default {
    modules: {
        shop: {
            filtersEnabled: true
        }
    },

    sliders: {
        hero: true,
        brands: false
    }
}
